function addRipple(button, e) {
  const width = button.offsetWidth;
  const pos = button.getBoundingClientRect();
  const rippleElement = document.createElement('span');
  rippleElement.classList.add('amedia-button-ripple');
  const left = e.clientX - pos.left;
  const top = e.clientY - pos.top;
  const size = Math.max(width - left, left) * 2;
  rippleElement.style.top = `${top - size / 2}px`;
  rippleElement.style.left = `${left - size / 2}px`;
  rippleElement.style.width = `${size}px`;
  rippleElement.style.height = `${size}px`;
  button.appendChild(rippleElement);
  setTimeout(() => button.removeChild(rippleElement), 1000);
}

let stylesAdded = false;
function addStyles() {
  if (stylesAdded === true) {
    return;
  }
  stylesAdded = true;
  document.head.insertAdjacentHTML(
    'beforeend',
    `
  <style>
      .amedia-button-ripple {
          background: #fff;
          border-radius: 100%;
          position: absolute;
          z-index: 1;
          transform: scale(0);
          animation: amedia-ripple 1000ms;
          opacity: .75;
      }

      @keyframes amedia-ripple {
          to {
              opacity: 0;
              transform: scale(1);
          }
      })
      </style>`
  );
}

class PrivacyButton extends HTMLElement {
  init() {
    if (this.connected) {
      return;
    }
    this.connected = true;
    addStyles();
    this.addEventListener('mousedown', (e) => addRipple(this, e));
    const text = this.textContent;
    this.innerHTML = `<button>${text}</button>`;
    this.addEventListener('mousedown', () => {
      window.dispatchEvent(new CustomEvent('privacy-box-button-clicked'));
    });
  }

  connectedCallback() {
    this.init();
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-privacybox-button', PrivacyButton);
} else {
  document.registerElement('amedia-privacybox-button', {
    prototype: PrivacyButton.prototype,
  });
}
