import { UserDataRequest, getLoginUrl } from '@amedia/user';

const data = {
  nb_NO: {
    authenticated: {
      title: '%navn%, her er en personvernpåminnelse',
      message:
        '%site% er en del av Amedia, og vi er ansvarlig for dine data. Vi bruker cookies og dine data til å forbedre og tilpasse tjenestene, tilbudene og annonsene du ser og bruker. Du kan når som helst endre dine innstillinger i Min aID.',
      moreBtn: 'Les mer',
      okBtn: 'OK – jeg forstår',
    },
    unauthenticated: {
      title: 'Personvern og cookies',
      message:
        '%site% er en del av Amedia, og vi er ansvarlig for dine data. Vi bruker cookies og dine data til å forbedre og tilpasse tjenestene, tilbudene og annonsene du ser og bruker.',
      moreBtn: 'Les mer',
      okBtn: 'OK – jeg forstår',
      loginCallToAction: 'Vil du lagre dine personverninnstillinger?',
      login: 'Logg inn med aID',
    },
  },
  nn_NO: {
    authenticated: {
      title: '%navn%, her er ei personvernpåminning',
      message:
        '%site% er ein del av Amedia, og vi er ansvarlege for dine data. Vi brukar cookies og dine data til å forbetre og tilpasse tenestene, tilboda og annonsane du ser og brukar. Du kan når som helst endre dine innstillingar i Min aID.',
      moreBtn: 'Les meir',
      okBtn: 'OK – Eg forstår',
    },
    unauthenticated: {
      title: 'Personvern og cookies',
      message:
        '%site% er ein del av Amedia, og vi er ansvarlege for dine data. Vi brukar cookies og dine data til å forbetre og tilpasse tenestene, tilboda og annonsane du ser og brukar.',
      moreBtn: 'Les meir',
      okBtn: 'OK – Eg forstår',
      loginCallToAction: 'Vil du lagre dine personverninnstillingar?',
      login: 'Logg inn med aID',
    },
  },
};

class AmediaPrivacy extends HTMLElement {
  init() {
    this.disable =
      window.location.href.indexOf('disable_privacy_box=true') > -1;
    const { userAgent } = window.navigator;
    if (
      this.getAttribute('in-review') !== null &&
      userAgent.includes(
        `pushvarslings-app/${this.getAttribute('app-version')}`
      )
    ) {
      this.parentNode.removeChild(this);
      return;
    }
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.publication = this.getAttribute('publication');
    document.body.appendChild(this);
    new UserDataRequest()
      .withContext('ego')
      .withAttributes(['name'])
      .withStorage(['euucp'])
      .fetch()
      .then(({ attributes, state, storage }) => {
        if (state.isLoggedIn) {
          this.initializeAuthenticated(attributes, storage.euucp);
        } else {
          this.initializeUnauthenticated();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  initializeAuthenticated(attributes, euucp) {
    if (
      typeof euucp === 'undefined' ||
      (euucp.viewed && (`${Date.now()}` - euucp.viewed) / 1000 > 60) ||
      euucp.button_clicked
    ) {
      this.parentNode.removeChild(this);
      return;
    }

    this.username = attributes.name ? attributes.name.split(' ')[0] : 'Hei'; // Just in case we don't have a name.
    euucp.viewed = `${Date.now()}`;
    euucp.save();

    window.addEventListener('privacy-box-button-clicked', () => {
      euucp.button_clicked = `${Date.now()}`;
      euucp.save();
      this.parentNode.removeChild(this);
    });

    this.renderBox('authenticated');
  }

  initializeUnauthenticated() {
    if (!window.localStorage || !window.sessionStorage) {
      this.parentNode.removeChild(this);
      return;
    }

    window.localStorage.euucp = window.localStorage.euucp || 0;
    const euucp = parseInt(window.localStorage.euucp, 10);

    if (euucp >= 2) {
      this.parentNode.removeChild(this);
      return;
    }

    if (!window.sessionStorage.euucp) {
      window.localStorage.euucp = euucp + 1;
      window.sessionStorage.euucp = 'true';
    }
    window.addEventListener('privacy-box-button-clicked', () => {
      this.parentNode.removeChild(this);
      window.localStorage.euucp = 3;
    });

    this.renderBox('unauthenticated');
  }

  renderBox(state) {
    if (this.disable) {
      this.setAttribute('style', 'display:none');
    }
    const language = this.getAttribute('language');
    const site = this.getAttribute('site') || 'Denne nettsiden';

    const title = data[language][state].title.replace('%navn%', this.username);
    const message = data[language][state].message.replace('%site%', site);
    const { okBtn } = data[language][state];
    const more = data[language][state].moreBtn;
    const login = data[language][state].login
      ? `
            <p class="login">
                ${data[language][state].loginCallToAction}
                <a href="${getLoginUrl({ context: 'euucp' })}">
                    ${data[language][state].login}
                </a>
            </p>`
      : '';

    const html = `
        <div>
            <h2>${title}</h2>
            <p>${message}</p>
            ${login}
            <div class="actionBar">
                <a class="lp_privacy_more" href="//www.amedia.no/personvern/">${more}</a>
                <amedia-privacybox-button class="lp_privacy_ok">${okBtn}</amedia-privacybox-button>
            </div>
            
        </div>`;
    this.insertAdjacentHTML('afterbegin', html);
    this.classList.add('active');
  }

  connectedCallback() {
    this.init();
  }

  attachedCallback() {
    this.init();
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-privacybox', AmediaPrivacy);
} else {
  document.registerElement('amedia-privacybox', {
    prototype: AmediaPrivacy.prototype,
  });
}
